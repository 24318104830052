import './App.css';
import React, { Suspense } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import DevUpIcon from "./assets/devup_transparent_v1.png";
const MainContent = React.lazy(() => import('./pages/MainContent'));
const SupportContent = React.lazy(() => import('./pages/SupportContent'));
const PrivacyContent = React.lazy(() => import('./pages/PrivacyContent'));

function App() {
  const [showMenu, setShowManu] = React.useState(false);

  return (
    <div className="App text-white">
      <header className='fixed top-0 left-0 w-full min-h-20 bg-[#00182F] content-center'>
        <a href="/">
         <img src={DevUpIcon} className='h-16 ml-10' alt='DevUp Icon' />
        </a>
        <div className="absolute right-10 top-4">
          <button className="text-white focus:outline-none" onClick={() =>setShowManu(!showMenu)}>
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        <div className={`absolute right-0 top-16 bg-[#00182F] w-48 rounded-lg shadow-lg ${ showMenu ? "hidden" : ""}`} id="menu">
          <ul className="text-white">
            <li className="p-2 hover:bg-blue-700 cursor-pointer"><a href="#home">Home</a></li>
            <li className="p-2 hover:bg-blue-700 cursor-pointer"><a href="#offers">Offers</a></li>
            <li className="p-2 hover:bg-blue-700 cursor-pointer"><a href="#partners">Partners</a></li>
            <li className="p-2 hover:bg-blue-700 cursor-pointer"><a href="#contact">Contact</a></li>
          </ul>
        </div>

      </header>
      <div>
      <Suspense
                        fallback={
                            <h3 className="pt-24 pb-3 ml-28 md:ml-72 lg:ml-[16.5rem] min-h-screen dark:text-white border-l-2 bg-gray-100 dark:bg-[color:var(--darkmode-background)">
                                Loading...
                            </h3>
                        }
                    >
      <Routes>
        <Route path="/" >
          <Route index element={<MainContent />} />
          <Route path="support" element={<SupportContent />}/>     
          <Route path="privacy-policy" element={<PrivacyContent />}/>
          <Route path="*" element={<MainContent />} />   
        </Route>
      </Routes>
      </Suspense>
      </div>
    </div>
  );
}

export default App;
